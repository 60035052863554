// 印尼语
export const lang = {
  language_code: "bahasa",
  Game:"Nama Permainan",
  Member:"Username",
  Bonus:"Bonus",
  Name:"Promo",
  query: "Cari",
  vip_query: "Cari Level VIP",
  home_page: "Beranda",
  preferential_activities: "Promosi",
  online_service: "layanan online",
  viplevel_query: "Pencarian Level VIP",
  fill_member_account: "Silahkan input username / ID",
  vip_level: "Mulai {msg}, Kredit pemain akan dikumpulkan dan disimpan sebagai data untuk meningkatkan  berbagai Level VIP  dan mendapatkan lebih banyak Promo Bonus sebagai bentuk penghargaan kepada seluruh anggota M98 yang terus-menerus memberikan kami dukungan . Jangan lupa ikuti M98 untuk menunggu PROMO TERBARU kami.",
  vip_level_tip: "Sistem akan memperbarui Level VIP dengan bonus Upgrade secara otomatis setelah giliran pemain dihitung.",
  vip_level_tip1: "Taruhan valid Anda pada hari itu akan dihitung oleh sistem lusa, dan level vip Anda juga akan diperbarui dan bonus promosi akan dikeluarkan pada saat itu.",
 vip_level_tip2: "Omzet dari menerima promosi tidak akan dihitung.",
  vip_t_exam: "Contoh",
  vip_exam: "Pemain A sejak mendaftar telah mengumpulkan kredit 100,000 setelah itu pemain dapat Upgrade Level ke VIP3 . Dapatkan Bonus Upgrade total dari VIP0 - VIP3 menjadi jumlah total ( 8+18+38+88=152 ) . Jika kredit pemain mencapai 300,000 dapat ditingkatkan ke Level VIP4. Dapatkan Bonus Upgrade dari total Level VIP0 - VIP4 hingga jumlah total mencapai（ 8 +18+38+88+188=340 ). Semakin tinggi TurnOver dan Level VIP yang dapat diupgrade maka semakin banyak Promo Bonus dan Hak Istimewa VIP yang dapat anda nikmati!",
  vipTh: [
    "Level VIP",
    "Nominal Betting",
    "Bonus Upgrade",
    "Bonus  Hari Spesial",
    "Bonus Ulang Tahun",
    // "Penawaran Setoran Bulanan",
    "Bonus Keuntungan",
    "Persentase Bonus Kekalahan",
    // "Domain Eksklusif",
    "Layanan Domain Eksklusif"
  ],
  queryTh: [
    "Bonus Upgrade",
    "Bonus Keuntungan",
    "Bonus Ulang Tahun",
    "Bonus Hari Spesial",
    "Bonus Kekalahan Bulanan",
    // "Penawaran Setoran Bulanan",
    // "Domain Eksklusif",
    // "Layanan Domain Eksklusif",
  ],
  tabData: [
    "Keuntungan Anggota VIP",
    // "bonus keberuntungan",
    // "Hadiah tabungan bulanan",
    "Deskripsi Keuntungan VIP",
  ],
  query_result: "Hasil Pencarian",
  dear: "Yang terhormat ",
  now_viplevel: "Level sebelumnya",
  current_active_bets: "Total Valid Betting",
  current_save_sum: "Total setoran saat ini",
  next_bet_need: "Jumlah Turnover yang harus dicapai",
  next_need_save: "Deposit diperlukan untuk promosi",
  level_after_promotion: "Level Selanjutnya",
  tab2_p1: "Bagaimana cara mengajukan diskon setoran bulanan?",
  tab2_p2: "Anggota hanya perlu memiliki 3 setoran dan catatan taruhan 100 atau lebih atau 1 3.000 atau lebih dalam satu minggu sebelum setiap aplikasi, dan kemudian menyetor lagi agar memenuhi syarat untuk berpartisipasi. (Penawaran ini hanya berlaku setelah deposit dan sebelum permainan dimainkan!) Detailnya adalah sebagai berikut:",
  tab1Th: ["LEVEL VIP","DEPOSIT EFEKTIF BULANAN","KEUNTUNGAN BONUS"],
  tab2Th: ["tingkat VIP","Penawaran Setoran","Jumlah aplikasi","Batas penawaran","Permintaan Penarikan"],
  tab1_p1: "Bagaimana cara mengajukan BONUS KEUNTUNGAN?",
  tab1_p2: "Tidak diperlukan mengajukan aplikasi. Sistem akan mengirimkan hadiah promosi sekitar pukul 14:00 (GMT+8) pada tanggal 5 setiap bulannya.Karena banyaknya jumlah kemenangan,sehingga diperlukan proses dalam pembayaran,mohon menunggu dengan sabar.",
  tab1_p2_u: "Anggota harus mencapai level VIP yang sesuai dan setoran valid yang diperlukan sebelum mereka bisa mendapatkan uang keberuntungan yang sesuai.",
  tab1_p3: "Member harus mencapai Level VIP yang sesuai dan mencapai  Deposit efektif yang diperlukan sebelum mereka dapat memperoleh Bonus yang sesuai.",
  tab1_p4: "Contoh: Level VIP pemain untuk bulan ini adalah VIP3, dan Deposit efektif untuk bulan ini adalah 100,000.Bulan berikutnya hanya bisa mendapatkan 88 bonus keberuntungan sesuai dengan level VIP3.",
  tab3_p1: "Bagaimana cara mengajukan Bonus Promosi?",
  tab3_p2: "Karena taruhan yang valid dari permainan para pemain perlu diperbarui pada hari berikutnya, untuk menghitung Level VIP setiap pemain dengan lebih akurat, sistem akan memperbarui Level VIP pada hari ketiga dan membagikan bonus promosi. Jika anda memiliki pertanyaan, silahkan hubungi customer service kami, terima kasih",
  tab3_p3: "Bagaimana cara mengajukan pembayaran  kekalahan bulanan?",
  tab3_p4: "Jika nominal kekalahan bulanan pemain mencapai 3,000 atau lebih, anda bisa mendapatkan bonus hingga 5% dari M98. Tidak perlu mengajukan promo ini karena  sistem akan otomatis mengirimkan bonus yang sesuai ke akun setiap pemain pada tanggal 15 setiap bulan. Tidak ada batasan dalam jumlah bonus.",
  tab3_p5: "Bagaimana cara mengajukan Bonus Ulang Tahun?",
  tab3_p6: "Hubungi customer service  online untuk memberikan data yang valid,setelah memeriksa maka sistem akan secara otomatis mengirimkan bonus yang sesuai pada hari ulang tahun anda ",
  tab3_p7: "( Untuk kenyamanan transaksi , silahkan hubungi customer service untuk melengkapi informasi tanggal lahir )",
  tab3_p8: "Bagaimana cara mengajukan Bonus Hari Spesial?",
  tab3_p9: "Aturan Promo: Setiap pemain hanya perlu melakukan Deposit yang valid  >2,000 dalam waktu 15 hari sebelum hari libur untuk memenuhi syarat  promo.",
  tab3_p9_u: "Aturan Pembayaran: Anggota hanya perlu melakukan deposit yang valid lebih dari 2.000 dalam waktu 15 hari sebelum festival agar memenuhi syarat untuk pembayaran.",
  tab3_p10: "Tentang layanan 24 jam",
  tab3_p11: "Kami memberikan pelayanan 24 jam selama setiap hari untuk semua member setia M98 . Seluruh customer service kami akan membantu proses mengenai  setoran dan penarikan online, dan taruhan game. Jika anda memiliki pertanyaan,silahkan berkonsultasi dengan customer service  online 24 jam.",
  tab3_p12: "Apa yang dimaksud dengan  Nama Domain Eksklusif VIP?",
  tab3_p13: "Untuk setiap pemain yang sudah mencapai Level VIP3 dapat berkonsultasi dengan customer service untuk mengajukan aplikasi domain ini.",
  tab3_p14: "Berpartisipasi dalam aktivitas VIP M98 berarti pemain telah menyetujui  Aturan dan Ketentuan Umum didalam situs M98 .",
  tab3_p15: "Bonus awal: hubungi layanan pelanggan LINE untuk mendaftar, sistem akan menyelesaikan transmisi pada tanggal 10 setiap bulan (GMT+8), dan hubungi layanan pelanggan LINE untuk melamar pada tanggal 10 hingga 15. Karena banyaknya bonus, mungkin ada keterlambatan masuk ke akun. Jika Anda tidak tiba tepat waktu, silakan hubungi kami beberapa kali!",
  tab3_p16: "Bonus yang hilang: hubungi layanan pelanggan LINE untuk mendaftar, sistem akan menyelesaikan transmisi pada tanggal 15 setiap bulan (GMT+8), dan menghubungi layanan pelanggan LINE dari tanggal 15 hingga 20 untuk mendaftar. Jika keuntungan negatif bulanan anggota mencapai lebih dari 3.000 yuan, perusahaan bisa mendapatkan hadiah tertinggi dari perusahaan. Bonus transfer 5%, tidak ada batas atas jumlah bonus.",
  wan: "Sepuluh ribu",
  qianwan: "sepuluh juta",
  yi: "100 juta",
  qianyi: "100 miliar",
  wanyi: "triliun",
  qianwanyi: "milion lipat empat",
  bei: "waktu",
  vipprivileges: " Keuntungan",
  no_account: "Akun member tidak ada, silahkan masukkan akun member yang benar",
  // 手机端
  officialIndex: "Beranda situs web",
  VIPquery: "Permintaan VIP",
  specialOffer: "Promosi",
  onlineCustomerService: "layanan online",
  Line: "Garis",
  //搜索
  VIPLevelQuery: "Permintaan VIP",
  PleaseInputAccount: "Sila masukkan akun anda",
  Query: "Menanyakan",
  //tab切换1
  MemberAdvantage: "Keuntungan anggota",
  MemberContent: "Dari {msg}, setiap taruhan valid yang Anda hasilkan akan diakumulasikan secara otomatis oleh sistem. Ketika akumulasi mencapai standar tertentu, dapat ditingkatkan secara otomatis ke level vip yang lebih tinggi. Semakin tinggi level VIP, semakin banyak aktivitas preferensial yang dapat diikuti dan semakin banyak bonus yang bisa diperoleh. Platform ini akan terus meluncurkan aktivitas preferensial untuk memberi penghargaan kepada anggota baru dan lama.",
  MemberTips: "Contoh: Anggota a dapat dipromosikan ke vip3 jika akumulasi taruhan yang valid mencapai 100.000 sejak pembukaan akun (Anda bisa mendapatkan 8+18+38+88=152 bonus promosi), dan 300.000 taruhan valid lainnya dapat dipromosikan ke vip4 (bisa mendapatkan 8+18+38+88=152 bonus promosi).Dapatkan 8+18+38+88+188=340 bonus promosi), semakin valid taruhan yang dihasilkan, semakin tinggi level VIP, dan semakin banyak diskon dan hak istimewa VIP yang Anda dapat Nikmati!",
  VIPLevel:"tingkat VIP",
  CumulativeEffectiveBetting:"Akumulasi taruhan yang valid",
  PromotionFiled:"bonus promosi",
  HolidayFiled:"bonus festival",
  BirthdayHandsel:"bonus ulang tahun",
  MonthlyDiscount:"Penawaran Setoran Bulanan",
  LuckyMoney:"bonus keberuntungan",
  NegativeGold:"Bonus negatif",
  ExclusiveDomainName:"Nama domain eksklusif",
  DedicatedService:"Layanan pelanggan eksklusif",
  //tab切换2
  LuckyMoneyTit: "bonus keberuntungan",
  HowApplyLuckyMoney: "Bagaimana cara mengajukan Bonus Keberuntungan?",
  LuckyContent1: "Bonus Keberuntungan: Tidak ada aplikasi yang diperlukan. Sistem akan merilisnya setelah pukul 14:00 (GMT+8) pada tanggal 5 setiap bulan. Karena banyaknya bonus, mungkin ada keterlambatan dalam masuk ke akun. Jika itu terjadi tidak tiba tepat waktu, harap menunggu dengan sabar.",
  LuckyContent1_u: "Bonus keberuntungan: hubungi layanan pelanggan LINE untuk mendaftar, sistem akan menyelesaikan transmisi pada tanggal 5 setiap bulan (GMT+8), dan hubungi layanan pelanggan LINE untuk melamar pada tanggal 5 hingga 10. Karena banyaknya bonus , mungkin ada keterlambatan kedatangan. , jika Anda tidak tiba tepat waktu, Anda dapat menghubungi kami beberapa kali!",
  LuckyContent2: "Anggota harus mencapai level VIP yang sesuai dan setoran valid yang diperlukan sebelum mereka bisa mendapatkan uang keberuntungan yang sesuai.",
  LuckyContent3: "Sebagai contoh: seorang anggota yang level VIPnya adalah VIP3 pada bulan berjalan, deposit efektif pada bulan berjalan adalah 100.000, dan hanya 88 uang keberuntungan yang sesuai dengan level VIP 3 yang dapat diperoleh di bulan berikutnya.",
  LuckyLevel: "tingkat VIP",
  MonthlyDeposit: "Setoran efektif bulanan",
  DowryGoodLuck: "selamat bertunangan",
  //tab切换3
  LavishGifts: "Hadiah tabungan bulanan",
  HowApplyMonthlyDepositDiscount: "Bagaimana cara mengajukan diskon setoran bulanan?",
  LavishContent: "Anggota hanya perlu memiliki 3 deposit dan catatan taruhan 100 atau lebih atau 1 3000 atau lebih dalam satu minggu sebelum setiap aplikasi, dan kemudian menyetor lagi untuk memenuhi syarat untuk berpartisipasi (Diskon hanya berlaku setelah deposit dan aplikasi sebelum permainan!) Detailnya adalah sebagai berikut:",
  Lavishlevel: "tingkat VIP",
  softLoan: "Penawaran Setoran",
  NumberRequested: "Jumlah aplikasi",
  OfferOnline: "Batas penawaran",
  withdrawals: "Permintaan Penarikan",
  //tab切换4
  PrivilegeThat: "Deskripsi Hak Istimewa",
  HowMonthlyNegativeInterestTranshipmentPayment: "Bagaimana cara mengajukan pembayaran transfer bunga negatif bulanan?",
  PrivilegeContent1: "Jika keuntungan negatif bulanan anggota mencapai lebih dari 3.000 yuan, Anda bisa mendapatkan bonus transfer maksimum 5% dari perusahaan. Tidak perlu mengajukan diskon ini. Sistem akan mengirimkan Anda bonus yang sesuai pada tanggal 15 setiap bulan , dan jumlah bonus tidak terbatas.",
  PrivilegeContent2: "Bagaimana cara melamar hadiah ulang tahun?",
  PrivilegeContent3: "Hubungi layanan pelanggan online untuk memberikan sertifikat yang valid, dan spesialis acara akan mengirimkan bonus yang sesuai pada hari ulang tahun Anda!",
  PrivilegeContent4: "(Untuk memastikan bahwa bonus dapat dikirimkan secara normal, harap hubungi spesialis layanan pelanggan untuk melengkapi informasi ulang tahun!)",
  PrivilegeContent5: "Bagaimana cara mengajukan uang hadiah liburan?",
  PrivilegeContent6: "Aturan pembayaran: Anggota hanya perlu melakukan deposit yang valid lebih dari 2.000 dalam waktu 15 hari sebelum festival agar memenuhi syarat untuk pembayaran",
  PrivilegeContent6_u: "Aturan pembayaran: Anggota hanya perlu melakukan deposit yang valid lebih dari 2.000 dalam waktu 15 hari sebelum festival agar memenuhi syarat untuk pembayaran",
  PrivilegeContent7: "Tentang layanan 24 jam",
  PrivilegeContent8: "Kami menyediakan 7X24 jam layanan pelanggan tanpa gangguan, setoran dan penarikan online, dan taruhan permainan.Jika Anda memerlukan bantuan, jangan ragu untuk berkonsultasi dengan layanan pelanggan online 24 jam.",
  PrivilegeContent9: "Apa yang dimaksud dengan nama domain eksklusif VIP?",
  PrivilegeContent10: "Anggota di atas level VIP3 dapat berkonsultasi dengan spesialis lini untuk mengajukan aplikasi.",
  PrivilegeContent11: "Dengan berpartisipasi dalam aktivitas VIP platform, Anda menyetujui 'Aturan dan Ketentuan Umum Promosi'",
  //弹窗
  QueryResult: "hasil pencarian",
  Exalted: "Terhormat:",
  YourCurrentVIPLevel: "Level VIP Anda saat ini:",
  CurrentActiveBets: "Taruhan aktif saat ini:",
  CurrentActBets: "Total simpanan saat ini:",

  BetsAreNeededQualify: "Promosi membutuhkan taruhan",
  VIPLevelAfterPromotion: "Level VIP setelah promosi",
  Close: "penutup",
  Birthday: "hadiah ulang tahun",
  Holiday: "hadiah liburan",
  Negative: "Bonus negatif",
  Monthly: "Penawaran Setoran Bulanan",
  exclusiveLine: "Nama domain eksklusif",
  exclusiveService: "Layanan pelanggan eksklusif",
  noData: "Akun member tidak ada, silahkan masukkan akun member yang benar",
  privilege: "hak istimewa",
  myriad: "Sepuluh ribu",
  AHundredMillion: "100 juta",
  double: "waktu",
  changeLanguage: "bahasa",
  normal: "Anggota biasa",
  // qianyi:"100 miliar",
  // wanyi:"triliun",
  // qianwan:"sepuluh juta",
  // wan:"Sepuluh ribu",
  // yi:"100 juta",
  // qianwanyi:"milion lipat empat",
  click_collection: "Satu klik untuk menerima",
  get_title: "Pemain platform yang terhormat, selamat untuk mendapatkan",
  get_fail: "Gagal menerima! Silakan coba lagi nanti",
  concern: "Tentu",
  tipBottom: "",
  promotionType: {
    1: "bonus promosi",
    2: "bonus festival",
    3: "bonus ulang tahun",
    4: "setoran bulanan",
    5: "bonus keberuntungan",
    6: "Bonus negatif"
  }
}