import Vue from 'vue'
import axios from './requests'
import qs from 'qs'
// import {Toast} from 'mint-ui'
// Vue.component(Toast)
export function post (url, data) {
    console.log(url, data);
    return new Promise((resolve, reject) => {
        axios.post(url, qs.stringify(data)).then(res => {
            if(res.data && res.data.error == '0'){
                resolve(res.data)
            }else{
                // Toast({
                //     message: res.data.data,
                //     duration: 2000
                // })
            }
            
        }, err => {
            // Toast({
            //     message: err,
            //     duration: 500
            // })
        })
    })
}
export function get (url, data, error) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(res => {
            resolve(res)
        }, err => {
            err = error ? error : err
            // Toast({
            //     message: err,
            //     duration: 500
            // })
        })
    })
}