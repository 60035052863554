export const lang = {
  language_code: "Ngôn ngữ",
  Game:"Game",
  Member:"Member",
  Bonus:"Bonus",
  Name:"Name",
  query: "truy vấn",
  vip_query: "VIP",
  home_page: "chính thức",
  preferential_activities: "Giảm giá",
  online_service: "DV khách hàng",
  viplevel_query: "Truy vấn cấp độ VIP",
  fill_member_account: "Nhập tài khoản",
  vip_level: "Bắt {msg}, hệ thống sẽ tự động tích lũy mỗi lần đặt cược hợp lệ mà bạn đặt cược, khi số điểm tích lũy đạt đến tiêu chuẩn nhất định có thể tự động nâng cấp lên cấp VIP cao hơn. Cấp VIP càng cao thì càng có nhiều hoạt động ưu đãi và càng nhận được nhiều tiền thắng, Nền tảng sẽ tiếp tục tung ra nhiều hoạt động ưu đãi hơn nữa để tặng lại cho thành viên mới và cũ.",
  vip_level_tip: "Cược hợp lệ của bạn trong ngày sẽ được tính  vào 2 ngày sau, và cấp độ VIP của bạn sẽ được cập nhật và tiền thưởng khuyến mãi sẽ được phát hành vào thời điểm đó.",
  vip_level_tip1: "Cược hợp lệ của bạn trong ngày sẽ được tính  vào 2 ngày sau, và cấp độ VIP của bạn sẽ được cập nhật và tiền thưởng khuyến mãi sẽ được phát hành vào thời điểm đó.",
  vip_level_tip2: "Doanh thu nhận khuyến mãi sẽ không được tính.",
  vip_t_exam: "thí dụ:",
  vip_exam: "Thành viên a đã tích lũy 100.000 cược hợp lệ kể từ khi mở tài khoản và có thể được thăng hạng lên VIP3 (nhận 8 + 18 + 38 + 88 = 152 tiền thưởng khuyến mãi), và 300.000 cược hợp lệ có thể được thăng hạng vip4 (nhận 8 + 18 + 38 + 88 + 188 = 340 tiền thưởng khuyến mãi), số lượng cược hợp lệ được tạo ra càng nhiều, cấp độ VIP càng cao, và bạn càng được hưởng nhiều chiết khấu và đặc quyền VIP.",
  vipTh: [
    "Cấp vip",
    "Cược tích lũy hiệu quả",
    "Phần thưởng khuyến mại",
    "Phần thưởng ngày lễ",
    "Phần thưởng sinh nhật",
    // "Ưu đãi tiền gửi hàng tháng",
    "Thưởng may mắn",
    "Thưởng lợi nhuận âm",
    // "Tên miền độc quyền",
    "Dịch vụ khách hàng"
  ],
  queryTh: [
    "Thưởng khuyến mại",
    "Thưởng may mắn",
    "Thưởng sinh nhật",
    "thưởng ngày lễ",
    "thưởng lợi nhuận âm",
    // "Ưu đãi tiền gửi hàng tháng",
    // "Tên miền độc quyền",
    // "Dịch vụ khách hàng tận tâm",
  ],
  tabData: [
    "Lợi thế thành viên",
    // "Thưởng may mắn",
    // "Quà tiết kiệm hàng tháng",
    "Mô tả đặc quyền",
  ],
  query_result: "Kết quả truy vấn",
  dear: "Kính thưa",
  now_viplevel: "Cấp VIP hiện tại của bạn",
  current_active_bets: "Cược hợp lệ hiện tại",
  current_save_sum: "Tổng số tiền gửi hiện tại",
  next_bet_need: "Đặt cược bắt buộc phải ứng trước",
  next_need_save: "Cần đặt cọc để được khuyến mãi",
  level_after_promotion: "Cấp độ VIP sau khi thăng hạng",
  tab2_p1: "Cách đăng ký ưu đãi tiền gửi hàng tháng?",
  tab2_p2: "Thành viên chỉ cần có 3 lần gửi tiền từ 100 trở lên hoặc 1 lần gửi tiền và hồ sơ cá cược từ 3000 trở lên trong vòng một tuần trước mỗi lần nộp đơn, và gửi tiền lại là đủ điều kiện tham gia. (Ưu đãi chỉ có giá trị sau khi nạp tiền và không áp dụng trước trận đấu!) Thông tin chi tiết như sau：",
  tab1Th: ["Cấp độ VIP","Khoản tiền gửi hợp lệ hàng tháng","Thưởng may mắn"],
  tab2Th: ["Cấp độ VIP","Ưu đãi gửi tiền","Số lượng đơn đăng ký","Giới hạn ưu đãi","Yêu cầu rút tiền"],
  tab1_p1: "Cách đăng ký Tiền thưởng May mắn？",
  tab1_p2: "Giải thưởng may mắn: Không cần đăng ký, hệ thống sẽ phát sau 14h00 ngày 05 hàng tháng (GMT + 8) Do số lượng giải thưởng lớn nên việc nhận thưởng có thể bị chậm trễ. kiên nhẫn nếu nó không được nhận trong thời gian sớm.",
  tab1_p2_u: "Phần thưởng may mắn: Hệ thống sẽ tính toán vào cuối mỗi ngày thứ 5 của tháng Đường dây liên hệ trong vòng 5-10 ngày để gửi yêu cầu nhận thưởng.",
  tab1_p3: "Thành viên cần đạt cấp độ VIP tương ứng và đạt được số tiền gửi hiệu quả theo yêu cầu trước khi có thể nhận được số tiền may mắn tương ứng.",
  tab1_p4: "Ví dụ: cấp độ VIP của thành viên tháng hiện tại là VIP3, số tiền nạp vào tháng hiện tại là 100.000 thì tháng tiếp theo chỉ có thể nhận được 88 tiền may mắn  tương ứng với cấp độ VIP 3",
  tab3_p1: "Cách đăng ký nhận quà khuyến mãi?",
  tab3_p2: "Xin chào bạn, vì mức cược hiệu quả của các trò chơi riêng lẻ cần được cập nhật vào ngày hôm sau, để tính toán cấp độ VIP của từng người chơi chính xác hơn, hệ thống sẽ cập nhật cấp độ VIP vào ngày thứ 3 và phân phối tiền thưởng khuyến mãi nếu bạn có. bất kỳ câu hỏi, xin vui lòng liên hệ với dịch vụ khách hàng trực tuyến của chúng tôi, cảm ơn bạn!",
  tab3_p3: "Cách đăng ký thanh toán chuyển khoản âm hàng tháng？",
  tab3_p4: "Nếu lợi nhuận âm hàng tháng của thành viên đạt trên 3.000VND, bạn có thể nhận được tiền thưởng chuyển khoản miễn phí của công ty lên đến 5%, không cần áp dụng chiết khấu này, hệ thống sẽ gửi tiền thưởng tương ứng cho bạn vào ngày 15 hàng tháng. Không có giới hạn trên về số tiền thưởng.",
  tab3_p5: "cách đăng ký quà tặng sinh nhật?",
  tab3_p6: "Hãy liên hệ với dịch vụ khách hàng trực tuyến để cung cấp chứng chỉ hợp lệ và chuyên gia tổ chức sự kiện sẽ gửi cho bạn phần thưởng tương ứng vào ngày sinh nhật của bạn!",
  tab3_p7: "(Để đảm bảo việc gửi tiền thắng được diễn ra bình thường, vui lòng liên hệ với chuyên viên chăm sóc khách hàng để điền đầy đủ thông tin ngày sinh!)",
  tab3_p8: "cách áp dụng cho quà tặng ngày lễ?",
  tab3_p9: "Quy tắc thah toán: Thành viên chỉ cần có số tiền gửi hợp lệ hơn 2000 trong vòng 15 ngày trước kỳ nghỉ để đủ điều kiện tham gia nhận thưởng.",
  tab3_p9_u: "Quy tắc thah toán: Thành viên chỉ cần có số tiền gửi hợp lệ hơn 2000 trong vòng 15 ngày trước kỳ nghỉ để đủ điều kiện tham gia nhận thưởng.",
  tab3_p10: "Về dịch vụ 24 giờ",
  tab3_p11: "Chúng tôi cung cấp cho bạn dịch vụ khách hàng 24/24 giờ liên tục, gửi tiền và rút tiền trực tuyến và cá cược trò chơi. Nếu bạn cần trợ giúp, vui lòng tham khảo dịch vụ khách hàng trực tuyến 24 giờ.",
  tab3_p12: "Tên miền độc quyền VIP là gì?",
  tab3_p13: "Đối với thành viên VIP3 trở lên, vui lòng tham khảo ý kiến ​​của chuyên gia đường dây để gửi đơn đăng ký.",
  tab3_p14: "Tham gia sự kiện Nền tảng VIP đồng nghĩa với việc bạn đã đồng ý với Quy định và điều khoản ưu đãi chung.",
  tab3_p15: "Thưởng nâng cấp: Hệ thống sẽ tính toán vào cuối mỗi ngày 10 của tháng Đường dây liên hệ trong vòng 10-15 ngày để gửi yêu cầu nhận thưởng.",
  tab3_p16: "Tiền thưởng thua lỗ: Hệ thống sẽ tính vào cuối mỗi ngày 15 của tháng. Những thành viên đã thua nhiều hơn 3000 baht trở lên, hãy liên hệ với đường dây trong vòng 15-20 ngày để gửi yêu cầu nhận tiền thưởng. Nhận tới 5% tiền thưởng không giới hạn",
  wan: "Vạn ",
  qianwan: "Nghìn vạn",
  yi: "Tỷ",
  qianyi: "Nghìn tỷ",
  wanyi: "vạn tỷ",
  qianwanyi: "Nghin vạn tỷ",
  bei: "gấp đôi",
  vipprivileges: "đặc ân",
  no_account: "Tài khoản thành viên không tồn tại, vui lòng nhập đúng tài khoản thành viên",
  // Thiết bị đầu cuối di động
  officialIndex: " chính thức",
  VIPquery: "VIP",
  specialOffer: "Giảm giá",
  onlineCustomerService: "DV khách hàng",
  Line: "Line",
  //Tìm kiếm.
  VIPLevelQuery: "Truy vấn VIP",
  PleaseInputAccount: "Nhập tài khoản",
  Query: "truy vấn",
  //chuyển hướng 1:
  MemberAdvantage: "Lợi thế thành viên",
  MemberContent: "Bắt {msg}, hệ thống sẽ tự động tích lũy mỗi lần đặt cược hợp lệ mà bạn đặt cược, khi số điểm tích lũy đạt đến tiêu chuẩn nhất định có thể tự động nâng cấp lên cấp VIP cao hơn. Cấp VIP càng cao càng có nhiều hoạt động ưu đãi và nhận được nhiều tiền thắng hơn, Nền tảng sẽ tiếp tục tung ra nhiều hoạt động ưu đãi tặng thưởng thành viên mới và cũ.",
  MemberTips: "Ví dụ: Thành viên a đã tích lũy 100.000 cược hợp lệ kể từ khi mở tài khoản và có thể được thăng hạng lên VIP3 (nhận 8 + 18 + 38 + 88 = 152 tiền thưởng khuyến mãi), và 300.000 cược hợp lệ có thể được thăng hạng vip4 (nhận 8 + 18 + 38 + 88 + 188 = 340 giải thưởng khuyến mãi), số lượng cược hợp lệ được tạo ra càng nhiều, cấp độ VIP càng cao, và bạn càng được hưởng nhiều chiết khấu và đặc quyền dành cho VIP!",
  VIPLevel:"Cấp VIP",
  CumulativeEffectiveBetting:"Cược tích lũy hiệu quả",
  PromotionFiled:"Thưởng khuyến mại",
  HolidayFiled:"Thưởng ngày lễ",
  BirthdayHandsel:"Thưởng sinh nhật",
  MonthlyDiscount:"Ưu đãi tiền gửi hàng tháng",
  LuckyMoney:"Thưởng may mắn",
  NegativeGold:"Thưởng lợi nhuận âm",
  ExclusiveDomainName:"Tên miền độc quyền",
  DedicatedService:"Dịch vụ khách hàng",
  //huyển hướng 2:
  LuckyMoneyTit: "Thưởng may mắn",
  HowApplyLuckyMoney: "Cách đăng ký Tiền thưởng May mắn",
  LuckyContent1: "Giải thưởng may mắn: Không cần đăng ký, hệ thống sẽ phát hành sau 14:00 ngày 5 (GMT + 8) hàng tháng. Do số lượng giải thưởng lớn nên việc nhận thưởng có thể bị chậm trễ. kiên nhẫn  nếu nó không được nhận trong thời gian sớm.",
  LuckyContent1_u: "Phần thưởng may mắn: Hệ thống sẽ tính toán vào cuối mỗi ngày thứ 5 của tháng Đường dây liên hệ trong vòng 5-10 ngày để gửi yêu cầu nhận thưởng.",
  LuckyContent2: "Thành viên cần đạt cấp độ VIP tương ứng và đạt được số tiền gửi hiệu quả theo yêu cầu trước khi có thể nhận được số tiền may mắn tương ứng.",
  LuckyContent3: "ví dụ;Cấp độ VIP của thành viên trong tháng hiện tại là VIP3, và số tiền gửi có hiệu lực cho tháng hiện tại là 100.000, tháng tiếp theo chỉ có thể nhận được 88 tiền thưởng may mắn tương ứng với cấp độ VIP 3.",
  LuckyLevel: "Cấp VIP:",
  MonthlyDeposit: "Hiệu lực tiền gửi hàng tháng",
  DowryGoodLuck: "Qùa may mắn",
  //Chuyến hướng 3:
  LavishGifts: "Quà tiết kiệm hàng tháng",
  HowApplyMonthlyDepositDiscount: "Cách đăng ký ưu đãi tiền gửi hàng tháng?",
  LavishContent: "Thành viên chỉ cần có 3 lần gửi tiền từ 100 trở lên hoặc 1 lần gửi tiền và hồ sơ cá cược từ 3000 trở lên trong tuần trước mỗi lần đăng ký, và gửi tiền lại để đủ điều kiện tham gia. (Chiết khấu chỉ có giá trị sau khi gửi tiền và không áp dụng trước trò chơi!) Các chi tiết như sau:",
  Lavishlevel: "Cấp VIP",
  softLoan: "Đề nghị đặt cọc",
  NumberRequested: "Số lượng đơn đăng ký",
  OfferOnline: "Cung cấp giới hạn trên",
  withdrawals: "Yêu cầu rút tiền",
  //chuyến hướng 4:
  PrivilegeThat: "Mô tả đặc quyền",
  HowMonthlyNegativeInterestTranshipmentPayment: "Cách đăng ký thanh toán chuyển khoản âm hàng tháng",
  PrivilegeContent1: "Nếu lợi nhuận âm hàng tháng của thành viên đạt trên 3.000VND, bạn có thể nhận được tiền thưởng chuyển khoản miễn phí của công ty lên đến 5%, không cần áp dụng chiết khấu này, hệ thống sẽ gửi tiền thưởng tương ứng cho bạn vào ngày 15 hàng tháng. Không có giới hạn trên về số tiền thưởng.",
  PrivilegeContent2: "cách đăng ký một  quà sinh nhật",
  PrivilegeContent3: "Hãy liên hệ với dịch vụ khách hàng trực tuyến để cung cấp chứng chỉ hợp lệ và chuyên gia tổ chức sự kiện sẽ gửi cho bạn phần thưởng tương ứng vào ngày sinh nhật của bạn!",
  PrivilegeContent4: "(Để đảm bảo việc gửi tiền thắng được diễn ra bình thường, vui lòng liên hệ với chuyên viên chăm sóc khách hàng để điền đầy đủ thông tin ngày sinh!)",
  PrivilegeContent5: "Làm thế nào để áp dụng cho quà tặng ngày lễ?",
  PrivilegeContent6: "Quy tắc thanh toán:Thành viên chỉ cần có một khoản tiền gửi hợp lệ trên 2000 trong vòng 15 ngày trước kỳ nghỉ để đủ điều kiện nhận tiền.",
  PrivilegeContent6_u: "Quy tắc thanh toán:Thành viên chỉ cần có một khoản tiền gửi hợp lệ trên 2000 trong vòng 15 ngày trước kỳ nghỉ để đủ điều kiện nhận tiền.",
  PrivilegeContent7: "Đối với dịch vụ 24 giờ",
  PrivilegeContent8: "Chúng tôi cung cấp cho bạn dịch vụ khách hàng 7X24 giờ liên tục, gửi và rút tiền trực tuyến, và cá cược trò chơi. Nếu bạn cần trợ giúp, vui lòng tham khảo dịch vụ khách hàng trực tuyến 24 giờ.",
  PrivilegeContent9: "Tên miền độc quyền VIP là gì?",
 PrivilegeContent10: "Đối với thành viên VIP3 trở lên, vui lòng tham khảo ý kiến   của chuyên gia đường dây để gửi đơn đăng ký.",
  PrivilegeContent11: "Tham gia sự kiện Nền tảng VIP đồng nghĩa với việc bạn đồng ý với Quy tắc và Điều khoản ưu đãi chung",
  //Cửa sổ bật lên
  QueryResult: "tìm kết quả",
  Exalted: "kính thưa:",
  YourCurrentVIPLevel: "Cấp độ VIP hiện tại của bạn:",
  CurrentActiveBets: "Cược hợp lệ hiện tại:",
  CurrentActBets: "Tổng số tiền gửi hiện tại:",

  BetsAreNeededQualify: "Đặt cược là bắt buộc phải ứng trước",
  VIPLevelAfterPromotion: "Cấp độ víp khi thăng hạng",
  Close: "tắt",
  Birthday: "Phần thưởng ngày lễ",
  Holiday: "Phần thưởng ngày lễ",
  Negative: "Thưởng lợi nhuận âm",
  Monthly: "Ưu đãi tiền gửi hàng tháng",
  exclusiveLine: "Phần thưởng lợi nhuận âm",
  exclusiveService: "Tên miền độc quyền",
  noData: "Tài khoản thành viên không tồn tại, vui lòng nhập đúng tài khoản thành viên",
  privilege: "đặc ân",
  myriad: "Vạn",
  AHundredMillion: "Tỷ",
  double: "gấp đôi",
  changeLanguage: "Ngôn ngữ",
  normal: "Thành viên bình thường",
  // qianyi:"Nghìn tỷ",
  // wanyi:"vạn tỷ",
  // qianwan:"Nghìn vạn",
  // wan:"Vạn",
  // yi:"Tỷ",
  // qianwanyi:"Nghin vạn tỷ",
  click_collection: "Bộ sưu tập bằng một cú nhấp chuột",
  get_title: "Kính gửi những người chơi Nền tảng, chúc mừng bạn đã nhận được",
  get_fail: "Yêu cầu không thành công! Vui lòng thử lại sau",
  concern: "Xác nhận",
  tipBottom: "",
  promotionType: {
    1: "Cược tích lũy hiệu quả",
    2: "Phần thưởng khuyến mại",
    3: "Phần thưởng ngày lễ",
    4: "Ưu đãi tiền gửi hàng tháng",
    5: "Phần thưởng sinh nhật",
    6: "Phần thưởng may mắn"
  }
}